import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import {
  CMasthead,
  CMastheadFragments,
  CMastheadBlock,
  CSeomatic,
  SeomaticData,
  CBackgroundImageCopy,
  CBackgroundImageCopyBlock,
  CBackgroundImageCopyFragments,
  CImageCopyBlocks,
  CImageCopyBlocksBlock,
  CImageCopyBlocksFragments,
  CCtaBox,
  CCtaBoxBlock,
  CCtaBoxFragments,
  CStats,
  CStatsBlock,
  CFindADoctor,
  CFindADoctorBlock,
  CCenteredCopy,
  CKrNewsletter,
  CHero,
  CHeroFragment,
  CHeroBlock,
  CComparisonTable,
  CContentWithSideImage,
  CContentWithSideImageBlock,
  CContentWithSideImageFragment,
  CTestimonialsSlider,
  CExpandedCtaSectionFragment,
  CExpandedCtaSectionBlock,
  CExpandedCtaSection,
  CFindADoctorFragments,
} from 'ui'
import { GetServerSidePropsContext } from 'next'
import { CraftGlobals } from 'tsconfig/craft-types'
import { useEffect, useContext, useState } from 'react'
import { throttle } from 'lodash'
import { HeaderContext } from 'ui/context/header'
import { gql } from '@urql/core'
import {
  CFeaturedItems,
  CFeaturedItemsBlock,
  CFeaturedItemsFragment,
} from 'ui/components/organisms/c-featured-items'
import {
  getSiteInfo,
  LANGUAGE_MAP,
  useSiteInfo,
  serverSideValidateWww,
} from 'shared'
import { defaultClient } from '../graphql/clients'
import fragments from '../helpers/gql-fragments'
import DefaultLayout from '../layouts/default'
import getGlobals from '../graphql/get-globals'
import { setCacheHeaders } from '../helpers/cache-config'

export async function getServerSideProps(context: GetServerSidePropsContext) {
  const isWww = serverSideValidateWww({ context })

  if (isWww) {
    return {
      notFound: true,
    }
  }

  const siteInfo = getSiteInfo(context.locale ?? '')
  const isUS = siteInfo.iso === LANGUAGE_MAP.US

  const global = await getGlobals(siteInfo.handle)

  const { data } = await defaultClient(siteInfo.handle).query(
    gql`
      query($uri: [String]!, $site: [String]!) {
        entry(uri: $uri, site: $site) {
          ${fragments.seomatic}
          uri
          sectionHandle
          typeHandle
          title
          ... on homepage_homepage_Entry {
            masthead {
              ...masthead
            }
            backgroundImageCopy3 {
              ...backgroundImageCopy3
            }
            imageCopyBlocks {
              ...imageCopyBlocks
            }
            backgroundImageCopy {
              ...backgroundImageCopy
            }
            imageCopyBlocks2 {
              ...imageCopyBlocks2
            }
            backgroundImageCopy2 {
              ...backgroundImageCopy2
            }
            imageCopyBlocks3 {
              ...imageCopyBlocks3
            }
            ctaBox {
              ...ctaBox
            }
            hero {
              ...hero
            }
            contentWithSideImage {
              ...contentWithSideImage
            }
            featuredItems {
              ...featuredItems
            }
            expandedCtaSection {
              ...expandedCtaSection
            }
            simpleContentWithSideImage {
              ...simpleContentWithSideImage
            }
            findADoctor {
              ...findADoctor
            }
          }
        }
      }
      ${CFindADoctorFragments.field}
      ${CExpandedCtaSectionFragment}
      ${CFeaturedItemsFragment}
      ${CContentWithSideImageFragment.default}
      ${CContentWithSideImageFragment.simple}
      ${CHeroFragment}
      ${CMastheadFragments.field}
      ${CBackgroundImageCopyFragments.field}
      ${CBackgroundImageCopyFragments.field2}
      ${CBackgroundImageCopyFragments.field3}
      ${CImageCopyBlocksFragments.field}
      ${CImageCopyBlocksFragments.field2}
      ${CImageCopyBlocksFragments.field3}
      ${CCtaBoxFragments.field}
    `,
    {
      uri: '__home__',
      site: siteInfo.handle,
    }
  )

  if (!data?.entry) {
    return {
      notFound: true,
    }
  }

  setCacheHeaders(context.res)

  return {
    props: {
      ...(await serverSideTranslations(context.locale ?? '', ['common'])),
      entry: data.entry,
      global,
      transparentHeader: !isUS,
    },
  }
}

type Props = {
  entry: {
    seomatic: SeomaticData
    masthead: Array<CMastheadBlock>
    backgroundImageCopy: Array<CBackgroundImageCopyBlock>
    backgroundImageCopy2: Array<CBackgroundImageCopyBlock>
    backgroundImageCopy3: Array<CBackgroundImageCopyBlock>
    imageCopyBlocks: Array<CImageCopyBlocksBlock>
    imageCopyBlocks2: Array<CImageCopyBlocksBlock>
    imageCopyBlocks3: Array<CImageCopyBlocksBlock>
    ctaBox: Array<CCtaBoxBlock>
    stats: Array<CStatsBlock>
    findADoctor: Array<CFindADoctorBlock>
    hero: Array<CHeroBlock>
    contentWithSideImage: Array<CContentWithSideImageBlock>
    featuredItems: Array<CFeaturedItemsBlock>
    expandedCtaSection: Array<CExpandedCtaSectionBlock>
    simpleContentWithSideImage: Array<CContentWithSideImageBlock>
  }
  global: CraftGlobals
  transparentHeader: boolean
}

export default function Index({ entry, global }: Props) {
  const siteInfo = useSiteInfo()
  const headerContext = useContext(HeaderContext)
  const [isScrollAtTop, setIsScrollAtTop] = useState(true)
  const [isMobile, setIsMobile] = useState(false)
  const mobileBreakpoint = 768

  const handleWindowScroll = () => {
    setIsScrollAtTop(window.pageYOffset === 0)
  }

  const handleWindowResize = () => {
    setIsMobile(window.innerWidth < mobileBreakpoint)
  }

  const handleWindowScrollThrottled = throttle(handleWindowScroll, 100)
  const handleWindowResizeThrottled = throttle(handleWindowResize, 100)

  useEffect(() => {
    handleWindowResize()
    window.addEventListener('scroll', handleWindowScrollThrottled)
    window.addEventListener('resize', handleWindowResizeThrottled)

    return () => {
      window.removeEventListener('scroll', handleWindowScrollThrottled)
      window.removeEventListener('resize', handleWindowResizeThrottled)
    }
  }, [])

  useEffect(() => {
    if (siteInfo.isUS) {
      headerContext?.setIsClear(false)
    } else {
      headerContext?.setIsClear(!isMobile && isScrollAtTop)
    }
  }, [isScrollAtTop, isMobile])

  return (
    <DefaultLayout global={global}>
      <CSeomatic data={entry.seomatic} />
      {siteInfo.isUS ? (
        <>
          <CHero field={entry.hero} />
          <CContentWithSideImage
            field={entry.simpleContentWithSideImage}
            isSimple
          />
          {global?.comparisonTable && (
            <CComparisonTable field={global?.comparisonTable} isUs />
          )}
          <CContentWithSideImage field={entry.contentWithSideImage} />
          <CFeaturedItems field={entry.featuredItems} />
          {global?.testimonialsSlider && (
            <CTestimonialsSlider field={global?.testimonialsSlider} />
          )}
          <CExpandedCtaSection field={entry.expandedCtaSection} />
          <CFindADoctor field={entry.findADoctor} />
          {global?.centeredCopy && (
            <CCenteredCopy field={global?.centeredCopy} />
          )}
        </>
      ) : (
        <>
          <CMasthead field={entry.masthead} isHomepage={true} />
          {siteInfo.isKR && (
            <CImageCopyBlocks
              field={[
                {
                  theme: 'teal',
                  imageAlignInline: 'start',
                  image: [
                    // @ts-ignore
                    {
                      url: 'https://optimise2.assets-servd.host/stimulating-bird/production/assets/images/final-new_section-1.jpg?w=1368&auto=compress%2Cformat&fit=crop&dm=1680130046&s=fa9d11fae44ff320d82de35dfb9d045d',
                    },
                  ],
                  heading: '이보 ICL의 장점',
                  copy: `
                      <ul style="list-style-type: none;">
                        <li>✔  각막 보존</li>
                        <li>✔  제거 및 복구 가능 <sup>1,5</sup></li>
                        <li>✔  미국 FDA 안전성 승인 <sup>6,7</sup></li>
                        <li>✔  안구건조증을 거의 유발하지 않음 <sup>1,2</sup></li>
                        <li>✔  빠른 회복 (다음날부터 일상생활 가능) <sup>4</sup></li>
                        <li>✔  짧은 수술 시간 <sup>3</sup></li>
                        <li>✔  부드러운 콜라머 (콜라겐 함유) 재질 <sup>8</sup></li>
                        <li>✔  얇은 각막도 수술 가능 <sup>9</sup></li>
                        <li>✔  개선된 야간 시력 제공 <sup>10-11</sup></li>
                        <li>✔  자외선 차단 기능</li>
                      </ul>
                    `,
                },
              ]}
            />
          )}
          <CBackgroundImageCopy field={entry.backgroundImageCopy3} />
          <CImageCopyBlocks field={entry.imageCopyBlocks} />
          <CBackgroundImageCopy field={entry.backgroundImageCopy} />
          <CImageCopyBlocks field={entry.imageCopyBlocks2} />
          <CBackgroundImageCopy field={entry.backgroundImageCopy2} />
          {!siteInfo.isES && (
            <>
              <CImageCopyBlocks field={entry.imageCopyBlocks3} />
              <CStats field={global.stats} />
            </>
          )}
          <CCtaBox field={entry.ctaBox} />
          <CFindADoctor field={global.findADoctor} />
          {!siteInfo.isES && <CCenteredCopy field={global.centeredCopy} />}
          {siteInfo.isKR && <CKrNewsletter />}
        </>
      )}
    </DefaultLayout>
  )
}
